/* src/styles.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.animateBg {

    justify-content: center;
    align-content: center;
    align-items: center;
    height: 100vh;
    background: repeating-linear-gradient(
            to right,
            rgba(250, 237, 205, 0.52),
            rgba(250, 237, 205, 0.52),
            rgba(250, 237, 205, 0.52)

    );
    background-size: 500% 400%;
    animation: animate-background 10s linear infinite alternate; /* Adjust duration and timing function */
    z-index: -100;
}

@keyframes animate-background {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 100% 100%;
    }
}

/* General styles */
body {
    font-family: 'Open Sans', sans-serif; /* Example font */
    color: #333;
    background-color: #f8f9fa;
}

.subtitle{
    font-size: 1.5rem;
    padding: 0 100px;
}

/* Container and alignment */
.centeredBox {
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;
    padding: 50px 20px;
    margin-top: 4%;
}


/* Typography */
h1 {

    font-weight: 500;
    color: #212529;
    font-size: 5rem;

}

p {
    font-size: 2rem;
    color: rgba(50, 54, 96, 0.6);
}
.text-primary {
    background: linear-gradient(
            to right,
            rgb(208, 145, 84),
            rgb(234, 206, 134),
            rgb(157, 212, 234),
            rgb(92, 150, 187)
    );
    background-size: 200% auto;
    background-clip: text;
    font-weight: 650;

    text-fill-color: transparent;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    -ms-text-fill-color: transparent;
    animation: animate-background 10s linear infinite alternate;
    display: inline; /* or 'inline-block' based on your design */
}

/* Buttons */
button {
    border: none;
    border-radius: 0.25rem;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button:hover {
    opacity: 0.9;
}

.transparent {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2rem; /* This will make it a circle */
    border: 0.8px solid #282c34; /* Adjust border thickness and color as needed */
    text-align: center;
    padding:5px;
    width: 25%;
    color: #6c757d;
    margin: auto; /* Center the circle */
}

.learn-more-button {
    background-color:  rgb(212, 163, 115); /* Adjust the background color as needed */
    color: #624221;
    padding: 15px 30px;
    border-radius: 1.5rem;
    font-size: 1.5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
    margin-top: 3%;
    text-decoration: none;
}

.learn-more-button:hover {
    background-color: rgb(250, 237, 205, 0.8); /* Adjust for hover state */
    border: 1px lightslategrey;

}


/* Animations */
.animate__animated {
    animation-duration: 1s;
    animation-fill-mode: both;
}

.animate__fadeIn {
    animation-name: fadeIn;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Responsive Design */
@media (max-width: 768px) {
    h1 {
        font-size: 2rem;
    }

    .centeredBox {
        padding: 30px 15px;
    }
}

.logo {
    height: 30px;
    padding: 30px;
}