.navbar {
    padding: 10px;
    background: tan;
    border-bottom: 1px solid #ddd; /* Small line (border) on the lower side */
}

.navcontain {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.navbar-brand img {
    max-height: 40px; /* Adjust as needed */
}

.contact-button {
    background-color: rgb(212, 163, 115); /* Adjust the background color as needed */
    color: black;
    border: none;
    padding: 10px 20px;
    border-radius: 1.2rem;
    font-size: 1rem;
    transition: background-color 0.3s ease;
}

.contact-button:hover {
    background-color: #E9EDC9; /* Adjust for hover state */
}

/* Ensure the button is not affecting the height of the navbar */
.navbar button {
    margin: 0;
    padding: 5px 15px;
}
